<template>
  <div class="bg-gold-50 h-screen w-full pt-4 overflow-y-auto relative">
    <div class="w-3/4 mx-auto">
      <ButtonRoyal
        @click="$emit('openPro')"
        class="ml-0 text-left w-full button-blue-primary"
        text="Go Pro"
        icon="diamond"
      >
      </ButtonRoyal>

      <a href="https://platform.openai.com/account/usage" target="_blank">
        <ButtonRoyal
          class="text-left ml-0 mt-8 button-blue w-full"
          text="Usage Dashboard"
          icon="bar_chart_4_bars"
        >
        </ButtonRoyal>
      </a>

      <ButtonRoyal
        @click="$emit('openSettings')"
        class="ml-0 mt-4 text-left w-full button-blue"
        :text="model"
        icon="settings_suggest"
      >
      </ButtonRoyal>
    </div>

    <div class="mt-4">
      <p v-if="user != null"> {{ user.email }}</p>
      <p v-else>Not Logged In</p>
    </div>

    <div class="text-purple-700 pb-4 absolute bottom-0 w-full text-center">
      <p>
        with ♥ by
        <a
          target="_blank"
          class="underline text-purple-700 font-bold"
          href="https://twitter.com/nickschnee"
          >@nickschnee</a
        >
      </p>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import ButtonRoyal from "./ButtonRoyal.vue";

export default {
  name: "ControlBar",
  components: {
    ButtonRoyal,
  },
  data() {
    return {
      // selectedCharacter: "You are GPT Royal, a helpful AI Assitant.",
      // selectedLength: "",
      // systemPrompt: "You are GPT Royal, a helpful AI Assitant.",
      // attachedPrompt: "",
    };
  },
  methods: {
    updateSelectedValues() {
      // this.systemPrompt =
      //   this.selectedCharacter +
      //   " " +
      //   this.selectedLength +
      //   " " +
      //   this.attachedPrompt;
      // this.$store.commit("updateSystemPrompt", this.systemPrompt);
      // localStorage.setItem("selectedCharacter", this.selectedCharacter);
      // localStorage.setItem("selectedLength", this.selectedLength);
      // localStorage.setItem("attachedPrompt", this.attachedPrompt);
      // localStorage.setItem("systemPrompt", this.systemPrompt);
    },

    updateSystemPrompt(event) {
      // this.systemPrompt = event.target.value;
      // this.$store.commit("updateSystemPrompt", this.systemPrompt);
    },
  },
  mounted() {
    // this.selectedCharacter =
    //   localStorage.getItem("selectedCharacter") ||
    //   "You are GPT Royal, a helpful AI Assitant.";
    // this.selectedLength = localStorage.getItem("selectedLength") || "";
    // this.attachedPrompt = localStorage.getItem("attachedPrompt") || "";
    // this.updateSelectedValues();
    // this.$store.commit("updateSystemPrompt", this.systemPrompt);
  },

  computed: {
    model() {
      if (this.$store.state.selectedModel == "gpt-3.5-turbo") {
        return "Model: GPT-3.5";
      } else {
        return "Model: GPT-4";
      }
    },

    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
