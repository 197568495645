<template>
  <div class="w-full max-h-128 max-w-xl overflow-y-auto">
    <ul class="list-none">
      <li
        v-for="prompt in filteredPrompts"
        :key="prompt.id"
        class="bg-gold-100 hover:bg-gold-200 mb-2 p-4 rounded"
      >
        <Accordion
          class="mt-2"
          titleBgColorOpen=""
          titleBgColor=""
          contentBgColor=""
          :title="prompt.title"
          :content="prompt.prompt"
        >
        </Accordion>
        <!-- 
        <h3
          @click="clickPrompt(prompt)"
          class="font-bold text-lg mb-0 cursor-pointer"
        >
          {{ prompt.title }}
        </h3>
        <p @click="clickPrompt(prompt)" class="mb-2 font-normal cursor-pointer">
          {{ prompt.prompt }}
        </p> -->

        <!-- SO MANY SEXY BUTTONS -->
        <!-- SO MANY SEXY BUTTONS -->
        <!-- SO MANY SEXY BUTTONS -->
        <!-- SO MANY SEXY BUTTONS -->

        <div class="flex">
          <ButtonRoyal
            @click="clickPrompt(prompt)"
            class="mx-0 button-blue-primary mb-4 mt-4 ml-0 mr-4"
            text="Use Prompt"
            icon="expand_circle_down"
          >
          </ButtonRoyal>

          <!-- If authenticated and promot not saved, save it!! -->
          <ButtonRoyal
            v-if="!isPromptSaved(prompt.id) && authenticated"
            @click="savePrompt(prompt.id)"
            class="mx-0 button-blue mb-4 mt-4 ml-0 mr-4"
            text="Save"
            icon="Favorite"
          >
          </ButtonRoyal>

          <!-- If not authenticated and promot not saved, log in!! -->
          <ButtonRoyal
            v-else-if="!isPromptSaved(prompt.id) && !authenticated"
            @click="$emit('openLogin')"
            class="mx-0 button-blue mb-4 mt-4 ml-0 mr-4"
            text="Save"
            icon="Favorite"
          >
          </ButtonRoyal>

          <!-- else, show remove button -->
          <ButtonRoyal
            v-else
            @click="removePrompt(prompt.id)"
            class="mx-0 button-blue mb-4 mt-4 ml-0 mr-4"
            text="Saved"
            icon="Done"
          >
          </ButtonRoyal>

          <!-- EDIT BUTTON -->
          <ButtonRoyal
            v-if="isPromptCustom(prompt.id) && authenticated"
            @click="editPrompt(prompt)"
            class="mx-0 button-blue mb-4 mt-4 ml-0 mr-4"
            text="Edit"
            icon="Edit"
          >
          </ButtonRoyal>

          <!-- DELETE BUTTON -->
          <ButtonRoyal
            v-if="isPromptCustom(prompt.id) && authenticated"
            @click="deletePrompt(prompt.id)"
            class="mx-0 button-red-secondary mb-4 mt-4 ml-0 mr-4"
            text="Delete"
            icon="Delete"
          >
          </ButtonRoyal>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import supabase from "@/supabase";
import ButtonRoyal from "@/components/ButtonRoyal.vue";
import Accordion from "@/components/Accordion.vue";

export default {
  components: {
    ButtonRoyal,
    Accordion,
  },
  props: {
    filteredPrompts: {
      type: Array,
      required: true,
    },
    authenticated: {
      type: Boolean,
      required: true,
    },
    savedPromptIds: {
      type: Array,
      required: true,
    },
    customPromptIds: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editPrompt(prompt) {
      this.$store.commit("editCustomPrompt", prompt);
    },

    async deletePrompt(id) {
      const confirmed = window.confirm(
        "Are you sure you want to delete this prompt? This action cannot be undone."
      );

      if (confirmed) {
        // console.log("deleting!!!", id);
        // console.log(this.user.id);

        const { error } = await supabase
          .from("prompts")
          .delete()
          .eq("id", id)
          .eq("user_id", this.user.id);

        if (error) {
          console.log(error);
        } else {
          // console.log("deleted!");
          this.$emit("reload");
        }
      }
    },

    clickPrompt(prompt) {
      this.$emit("clickPrompt", prompt);
    },
    isPromptSaved(promptId) {
      return this.savedPromptIds.includes(promptId);
    },
    isPromptCustom(promptId) {
      return this.customPromptIds.includes(promptId);
    },
    savePrompt(promptId) {
      this.$emit("savePrompt", promptId);
    },
    removePrompt(promptId) {
      this.$emit("removePrompt", promptId);
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
