import { createStore } from "vuex";

export default createStore({
  state: {
    apiKey: localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "",
    apiKeyValid: false,
    chats: localStorage.getItem("chats")
      ? JSON.parse(localStorage.getItem("chats"))
      : [],
    currentChat: 0,
    chatError: false,
    selectedModel: localStorage.getItem("selectedModel") || "gpt-3.5-turbo",
    systemPrompt:
      localStorage.getItem("systemPrompt") ||
      "You are GPT Royal, a helpful AI Assitant.",
    systemPromptTitle: localStorage.getItem("systemPromptTitle") || "GPT Royal",
    templatePrompt: "",
    defaultChatHistory: [
      {
        id: 1,
        role: "system",
        content: localStorage.getItem('systemPrompt') || "You are GPT Royal, a helpful AI Assitant.",
      },
    ],
    user: null,
    editCustomPrompt: false,
  },
  getters: {
    getChatById: (state) => (id) => {
      return state.chats.find((chat) => chat.id === id);
    },

    getUser(state) {
      return state.user;
    },

    getAuth(state) {
      if (state.user) {
        return true;
      } else {
        return false;
      }
    },

    getEditCustomPrompt(state) {
      // console.log("get edit custom prompt is now ", state.editCustomPrompt);
      return state.editCustomPrompt;
    },
  },
  mutations: {
    editCustomPrompt(state, prompt) {
      state.editCustomPrompt = prompt;
      // console.log("edit custom prompt is now " + state.editCustomPrompt);
    },

    setUser(state, user) {
      state.user = user;
      // console.log("User set:", state.user);
    },

    updateModel(state, model) {
      state.selectedModel = model;
      localStorage.setItem("selectedModel", model);
    },

    updateTemplatePrompt(state, prompt) {
      state.templatePrompt = prompt;
      // console.log(state.templatePrompt);
    },

    updateSystemPrompt(state, prompt) {
      state.defaultChatHistory[0].content = prompt;
      state.systemPrompt = prompt;
      localStorage.setItem('systemPrompt', prompt);
    },

    updateSystemPromptTitle(state, prompt) {
      state.systemPromptTitle = prompt;
      localStorage.setItem('systemPromptTitle', prompt);
    },

    saveKey(state, key) {
      state.apiKey = key;
      state.apiKeyValid = true;
      localStorage.setItem("apiKey", key);
    },

    deleteKey(state) {
      state.apiKey = "";
      state.apiKeyValid = false;
      localStorage.removeItem("apiKey");
    },

    showChatError(state, payload) {
      state.chatError = payload;
    },

    newChat(state, chatElement) {
      console.log("making new chat in store");
      // console.log("new chat id will be " + (state.chats.length + 1))

      const newChat = {
        id: state.chats.length + 1,
        name: "generating title ...",
        chatHistory: [...state.defaultChatHistory],
      };

      newChat.chatHistory.push(chatElement);

      state.chats.push(newChat);

      console.log(state.chats);

      state.currentChat = newChat.id;
    },

    updateChat(state, chatElement) {
      const chat = state.chats.find((chat) => chat.id === state.currentChat);

      const newMessage = {
        id: chatElement.id,
        role: chatElement.role,
        content: chatElement.content,
      };

      chat.chatHistory.push(newMessage);

      localStorage.setItem("chats", JSON.stringify(state.chats));

      if (chatElement.id === 3) {
        fetch("https://api.openai.com/v1/chat/completions", {
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content:
                  "You very good in summarising a statement. Come up with a title for this message-thread, using maximum 5 words.",
              },
              {
                role: "user",
                content: chatElement.content,
              },
            ],
            temperature: 0.3,
            max_tokens: 2000,
          }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.apiKey,
          },
        })
          .then((response) => {
            console.log(response); //If you want to check the full response
            if (response.ok) {
              response.json().then((json) => {
                chat.name = json.choices[0].message.content;

                localStorage.setItem("chats", JSON.stringify(state.chats));
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    changeChat(state, chatId) {
      state.currentChat = chatId;
      state.chatError = false;
    },
  },

  actions: {
    addChat(context, chatElement) {
      return new Promise((resolve, reject) => {
        context.commit("addChat", chatElement);
        resolve(context.state.chats.slice(-1)[0].id); // return the new chat's id
      });
    },
  },
  modules: {},
});
