import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.css';

import supabase from '@/supabase';

async function handleAuthCallback(session, user) {
    if (user) {
      // Use the user object
      // console.log('Authenticated user:', user);

      store.commit("setUser", user);
      
      // Check if user exists in the public users table
      const { data, error } = await supabase
        .from('users')
        .select('id')
        .eq('id', user.id)
  
      if (error) {
        // console.error('Error fetching user:', error);

      } else if (data.length == 0) {
        // User does not exist, insert the user id and email address into the table
        const { insertError } = await supabase
          .from('users')
          .insert([{ id: user.id, email: user.email }]);
  
        if (insertError) {
          console.error('Error inserting user:', insertError);
        } else {
          // console.log('User added to users table');
        }
      } else {
        // console.log('User already exists in public_users table');
      }

    } else {
      // User is not authenticated
      console.log('User is not authenticated');
    }
  }

supabase.auth.onAuthStateChange((event, session) => {
  handleAuthCallback(session, session?.user);
});

createApp(App).use(store).use(router).mount('#app');
