<template>
  <div class="relative">
    <Magiclink
      class="modal-wrapper"
      v-if="showModalMagicLink"
      @close="showModalMagicLink = false"
    >
    </Magiclink>

    <ModalKey class="modal-wrapper" v-if="showModal" @close="showModal = false">
    </ModalKey>

    <ModalPro
      class="modal-wrapper"
      v-if="showModalPro"
      @close="showModalPro = false"
    >
    </ModalPro>

    <ModalPrompts
      class="modal-wrapper"
      v-if="showModalPrompts"
      @close="showModalPrompts = false"
    >
    </ModalPrompts>

    <ModalCharacter
      class="modal-wrapper"
      v-if="showModalCharacter"
      @close="showModalCharacter = false"
      @openLogin="showModalMagicLink = true"
    >
    </ModalCharacter>

    <ModalSettings
      v-if="showSettings"
      @close="showSettings = false"
      class="modal-wrapper"
    >
    </ModalSettings>

    <!-- DESKTOP START -->
    <!-- DESKTOP START -->
    <!-- DESKTOP START -->

    <div class="hidden md:block bg-gold-50">
      <div class="grid grid-cols-8 lg:grid-cols-6">
        <div class="col-span-2 lg:col-span-1">
          <SideBar @openPro="showModalPro = true" />
        </div>
        <div class="col-span-4 lg:col-span-4 h-screen">
          <Chat
            class="rounded-xl"
            @openCharacter="showModalCharacter = true"
            @openPrompts="showModalPrompts = true"
            @open="showModal = true"
          />
        </div>
        <div class="col-span-2 lg:col-span-1">
          <ControlBar
            @openPrompts="showModalPrompts = true"
            @openPro="showModalPro = true"
            @openSettings="showSettings = true"
          />
        </div>
      </div>
    </div>

    <!-- DESKTOP END -->
    <!-- DESKTOP END -->
    <!-- DESKTOP END -->

    <!-- MOBILE START -->
    <!-- MOBILE START -->
    <!-- MOBILE START -->

    <div class="block md:hidden">
      <div class="container flex flex-col md:flex-row">
        <div class="chat-container w-full md:w-/4">
          <Chat
            @openCharacter="showModalCharacter = true"
            @openPrompts="showModalPrompts = true"
            @toggleChats="toggleSidebar()"
            @toggleControls="toggleControlbar()"
            @open="showModal = true"
          />
        </div>

        <div :class="['sidebar-container', { hidden: !showSidebar }]">
          <div
            class="sidebar flex w-3/4 h-full bg-white shadow-lg absolute top-0 bottom-0"
          >
            <SideBar
              class="w-5/6"
              @openPro="showModalPro = true"
              @click="toggleSidebar()"
            />
            <div
              class="w-1/6 close-text bg-gold-500 text-white font-bold text-center"
              @click="toggleSidebar()"
            >
              CLOSE
            </div>
          </div>
        </div>

        <div :class="['controlbar-container', { hidden: !showControlbar }]">
          <div
            class="sidebar flex w-3/4 h-full bg-white shadow-lg absolute top-0 bottom-0 right-0"
          >
            <div
              class="w-1/6 close-text bg-gold-500 text-white font-bold text-center"
              @click="toggleControlbar()"
            >
              CLOSE
            </div>
            <ControlBar
              class="w-5/6"
              @openPrompts="showModalPrompts = true"
              @openPro="showModalPro = true"
              @openSettings="showSettings = true"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- MOBILE END -->
    <!-- MOBILE END -->
    <!-- MOBILE END -->
  </div>
</template>

<script>
import Chat from "@/components/Chat.vue";
import SideBar from "@/components/SideBar.vue";
import ModalKey from "@/components/ModalKey.vue";
import ModalPro from "@/components/ModalPro.vue";
import ControlBar from "@/components/ControlBar.vue";
import ModalPrompts from "@/components/ModalPrompts.vue";
import ModalSettings from "@/components/ModalSettings.vue";
import ModalCharacter from "@/components/ModalCharacter.vue";
import Magiclink from "@/components/Magiclink.vue";
import { assertTSExpressionWithTypeArguments } from "@babel/types";

export default {
  name: "HomeView",
  components: {
    Chat,
    SideBar,
    ModalKey,
    ModalPro,
    ControlBar,
    ModalPrompts,
    ModalSettings,
    ModalCharacter,
    Magiclink,
  },
  data() {
    return {
      showModal: false, // Set showModal to false initially
      showModalPro: false, // Set showModal to false initially
      showSidebar: false,
      showControlbar: false,
      showModalPrompts: false,
      showSettings: false,
      showModalCharacter: false,
      showModalMagicLink: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    toggleControlbar() {
      this.showControlbar = !this.showControlbar;
    },
  },
};
</script>

<style>
.modal-wrapper {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center;
}

.close-text {
  display: flex; /* enable flexbox layout */
  align-items: center; /* vertically align the text */
  justify-content: center; /* horizontally align the text */
  writing-mode: vertical-lr; /* set the text to be vertical */
  transform: rotate(-180deg); /* rotate the text to be tilted to the left */
}
</style>
