<template>
  <div class="w-full">
    <div
      v-if="selectedCategory == 'Custom' && !authenticated"
      class="text-left"
    >
      You have to log in to create custom prompts.

      <ButtonRoyal
        @click="$emit('openLogin')"
        class="button-blue-primary mb-4 mt-4 ml-0"
        text="Log In"
        icon="Login"
      >
      </ButtonRoyal>
    </div>

    <div
      v-else-if="selectedCategory == 'Custom' && authenticated"
      class="text-left"
    >
      <ButtonRoyal
        v-if="this.promptCreator == false"
        @click="this.promptCreator = true"
        class="button-blue-primary mb-4 mt-4 ml-0"
        text="Create Prompt"
        icon="add"
      >
      </ButtonRoyal>

      <PromptCreator
        :preFillPrompt="editPrompt"
        @fetchPrompts="$emit('fetchPrompts')"
        v-if="promptCreator || editPrompt"
        @close="close"
      >
      </PromptCreator>
    </div>
  </div>
</template>

<script>
import ButtonRoyal from "@/components/ButtonRoyal.vue";
import PromptCreator from "@/components/PromptLibrary/PromptCreator.vue";

export default {
  components: {
    ButtonRoyal,
    PromptCreator,
  },
  data() {
    return {
      promptCreator: false,
    };
  },
  props: {
    selectedCategory: {
      type: String,
      required: true,
    },
    authenticated: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close() {
      this.promptCreator = false;
      this.$store.commit("editCustomPrompt", false);
    },
  },
  computed: {
    editPrompt() {
      return this.$store.getters.getEditCustomPrompt;
    },
  },
};
</script>
