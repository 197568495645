<template>
  <transition name="fade">
    <div
      class="fixed inset-0 z-10 flex items-center justify-center"
      @click.self="$emit('close')"
    >
      <div
        class="bg-black opacity-75 absolute inset-0"
        @click="$emit('close')"
      ></div>

      <div class="m-0 sm:m-4 w-full md:w-11/12 lg:w-3/4 xl:w-2/3 max-w-7xl border-2 p-2 md:p-8 z-10 relative bg-white overflow-y-auto max-h-screen sm:max-h-full">
        <div v-if="!showEdit" class="w-full mx-auto text-left">
          <h2 class="text-lg sm:text-3xl font-bold">Prompt Library</h2>
          <p class="text-sm sm:text-xl mt-4">
            Choose a prompt from the library to assign a new role to GPT Royal.
            For example, you can tell it to act like a teacher, a pair
            programmer or a lunatic. Browse and save public prompts for easy
            access or create your own.
          </p>

        </div>

        <div v-if="!showEdit" class="flex max-h-screen my-8">
          <!-- SIDEBAR LEFT -->
          <!-- SIDEBAR LEFT -->
          <!-- SIDEBAR LEFT -->
          <!-- SIDEBAR LEFT -->

          <LeftSidebar
            :categories="categories"
            :selectedCategory="selectedCategory"
            :savedPromptIds="savedPromptIds"
            :customPromptIds="customPromptIds"
            @selectCategory="selectCategory"
          />

          <!-- MAIN WINDOW -->
          <!-- MAIN WINDOW -->
          <!-- MAIN WINDOW -->
          <!-- MAIN WINDOW -->

          <div class="w-2/3 sm:w-3/4">
            <CategoryTitle :selectedCategory="selectedCategory" />

            <ContentSavedPrompts
              :selectedCategory="selectedCategory"
              :authenticated="authenticated"
              :savedPromptIds="savedPromptIds"
              @openLogin="$emit('openLogin')"
            />

            <ContentCustomPrompts
              :selectedCategory="selectedCategory"
              :authenticated="authenticated"
              :requestEdit="requestEdit"
              @openLogin="$emit('openLogin')"
              @fetchPrompts="reloadAfterInsert"
            />

            <!-- Loops all Prompts in filteredPrompts -->

            <PromptLoader
              :filteredPrompts="filteredPrompts"
              :authenticated="authenticated"
              :savedPromptIds="savedPromptIds"
              :customPromptIds="customPromptIds"
              @clickPrompt="clickPrompt"
              @savePrompt="savePrompt"
              @removePrompt="removePrompt"
              @openLogin="$emit('openLogin')"
              @reload="reloadAfterInsert"
            />

            <ButtonRoyal
              v-if="systemPromptTitle !== 'GPT Royal'"
              @click="selectPrompt(false)"
              class="button-red-secondary ml-0 mt-2"
              text="Reset"
              icon="restart_alt"
            >
            </ButtonRoyal>

          </div>

          <!-- CLOSE BUTTON -->
          <!-- CLOSE BUTTON -->
          <!-- CLOSE BUTTON -->
          <!-- CLOSE BUTTON -->

          <div class="absolute top-0 right-0 p-4">
            <button
              @click="$emit('close')"
              class="text-black text-2xl font-bold"
            >
              X
            </button>
          </div>
        </div>

        <!-- CUSTOMIZER -->
        <!-- CUSTOMIZER -->
        <!-- CUSTOMIZER -->
        <!-- CUSTOMIZER -->
        <!-- CUSTOMIZER -->

        <div v-else class="text-left">
          <h1 class="text-2xl text-black">
            Customize {{ clickedPrompt.title }}
          </h1>

          <p>You can edit your selected prompt here.</p>

          <div class="flex max-h-screen py-4">
            <textarea
              class="bg-gold-100 p-4 rounded w-full md:w-2/3 h-36"
              v-model="clickedPrompt.prompt"
            ></textarea>
          </div>

          <ButtonRoyal
            class="button-primary text-left ml-0"
            text="Use Prompt"
            icon="magic_button"
            @click="selectPrompt(clickedPrompt)"
          >
          </ButtonRoyal>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import supabase from "@/supabase";

import Accordion from "@/components/Accordion.vue";
import ButtonRoyal from "@/components/ButtonRoyal.vue";
import Magiclink from "@/components/Magiclink.vue";
import PromptLoader from "@/components/PromptLibrary/PromptLoader.vue";
import CategoryTitle from "@/components/PromptLibrary/CategoryTitle.vue";
import ContentSavedPrompts from "@/components/PromptLibrary/ContentSavedPrompts.vue";
import ContentCustomPrompts from "@/components/PromptLibrary/ContentCustomPrompts.vue";
import LeftSidebar from "@/components/PromptLibrary/LeftSidebar.vue";

export default {
  name: "ModalPrompts",
  components: {
    Accordion,
    ButtonRoyal,
    Magiclink,
    PromptLoader,
    CategoryTitle,
    ContentSavedPrompts,
    LeftSidebar,
    ContentCustomPrompts,
  },
  data() {
    return {
      prompts: [],
      selectedCategory: "Saved",
      showEdit: false,
      clickedPrompt: false,
      savedPromptIds: [],
      customPromptIds: [],
      requestEdit: {},
    };
  },

  created() {
    this.init();
  },

  mounted() {},
  methods: {

    reloadAfterInsert() {
      this.init();
      this.selectCategory(-2);
    },

    async init() {
      // Example: Fetch data from a Supabase table called "prompts"
      const { data, error } = await supabase
        .from("prompts")
        .select(
          `id, title, prompt, category:categories (name, id), popularity, public, created_at`
        )
        .order("popularity", { ascending: false });

      if (error) {
        console.error("Error fetching data:", error);
      } else {
        // console.log("Fetched data:", data);
        this.prompts = data;
      }

      // START FUNCTION TO GET SAVED PROMPT IDS
      // START FUNCTION TO GET SAVED PROMPT IDS
      // START FUNCTION TO GET SAVED PROMPT IDS
      // START FUNCTION TO GET SAVED PROMPT IDS
      // START FUNCTION TO GET SAVED PROMPT IDS
      // START FUNCTION TO GET SAVED PROMPT IDS

      // Only Proceed if user is authenticated
      if (this.authenticated) {
        const { data: savedPromptsData, error: savedPromptsError } =
          await supabase
            .from("user_saved_prompt")
            .select("prompt_id")
            .eq("user_id", this.user.id);

        // Function to check if an ID is unique in the array
        const isUniqueId = (id) => {
          return !this.savedPromptIds.includes(id);
        };

        if (savedPromptsError) {
          console.error("Error fetching saved prompts:", savedPromptsError);
        } else {
          // console.log("Fetched saved prompts:", savedPromptsData);

          savedPromptsData.forEach((savedPrompt) => {
            if (isUniqueId(savedPrompt.prompt_id)) {
              this.savedPromptIds.push(savedPrompt.prompt_id);
            }
          });

          // console.log(this.savedPromptIds);
        }
      }

      // START FUNCTION TO GET CUSTOM PROMPTS FOR USER
      // START FUNCTION TO GET CUSTOM PROMPTS FOR USER
      // START FUNCTION TO GET CUSTOM PROMPTS FOR USER
      // START FUNCTION TO GET CUSTOM PROMPTS FOR USER
      // START FUNCTION TO GET CUSTOM PROMPTS FOR USER
      // START FUNCTION TO GET CUSTOM PROMPTS FOR USER

      if (this.authenticated) {
        const { data: customPromptsData, error: customPromptsError } =
          await supabase
            .from("prompts")
            .select("id")
            .eq("user_id", this.user.id);

        if (customPromptsError) {
          console.error("Error fetching custom prompts:", customPromptsError);
        } else {
          // console.log("Fetched custom prompts:", customPromptsData);

          customPromptsData.forEach((customPrompt) => {
            if (customPrompt.id) {
              this.customPromptIds.push(customPrompt.id);
            }
          });

          // console.log(this.customPromptIds);
        }
      }
      // attempting to reload prompts
    },

    async savePrompt(prompt_id) {
      try {
        const { data, error } = await supabase
          .from("user_saved_prompt")
          .insert([{ user_id: this.user.id, prompt_id: prompt_id }]);

        if (error) {
          console.error("Error inserting data:", error);
        } else {
          // console.log("Data inserted successfully:", data);

          // Update the frontend by adding the prompt_id to the savedPromptIds array
          this.savedPromptIds.push(prompt_id);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    },

    isPromptSaved(promptId) {
      return this.savedPromptIds.includes(promptId);
    },

    async removePrompt(promptId) {
      // Remove the prompt from the savedPromptIds array
      this.savedPromptIds = this.savedPromptIds.filter((id) => id !== promptId);

      // Remove the prompt from the database
      const { data: deletedData, error: deleteError } = await supabase
        .from("user_saved_prompt")
        .delete()
        .eq("user_id", this.user.id)
        .eq("prompt_id", promptId);

      if (deleteError) {
        console.error("Error deleting saved prompt:", deleteError);
      } else {
        // console.log("Deleted saved prompt:", deletedData);
      }
    },

    clickPrompt(prompt) {
      this.showEdit = true;
      this.clickedPrompt = prompt;

      // console.log(this.clickedPrompt);
    },

    async increasePopularity(id) {
      // console.log(id);

      // Fetch the existing popularity value
      const { data: fetchData, error: fetchError } = await supabase
        .from("prompts")
        .select("popularity")
        .eq("id", id)
        .single();

      if (fetchError) {
        console.error("Error fetching data:", fetchError);
        return;
      }

      // Perform the update operation
      const { error: updateError } = await supabase
        .from("prompts")
        .update({ popularity: (fetchData.popularity || 0) + 1 })
        .eq("id", id);

      if (updateError) {
        console.error("Error updating data:", updateError);
      } else {
        // console.log("Update successful");
      }
    },
    selectCategory(category) {
      if (category == 0) {
        this.selectedCategory = false;
        return;
      } else if (category == -1) {
        this.selectedCategory = "Saved";
        return;
      } else if (category == -2) {
        this.selectedCategory = "Custom";
        return;
      } else if (category == -3) {
        this.selectedCategory = "Public";
        return;
      }

      this.selectedCategory = category.category_name;

      // console.log(this.selectedCategory);
      // console.log(category.name)
    },

    selectPrompt(prompt) {
      if (prompt == false) {
        this.$store.commit(
          "updateSystemPrompt",
          "You are GPT Royal, a helpful AI Assitant."
        );
        this.$store.commit("updateSystemPromptTitle", "GPT Royal");
        // this.$store.commit('updateTemplatePrompt', '');
        this.$emit("close");

        return;
      }

      this.$store.commit("updateSystemPrompt", prompt.prompt);
      this.$store.commit("updateSystemPromptTitle", prompt.title);

      // this.$store.commit('updateTemplatePrompt', prompt);
      this.$emit("close");

      this.increasePopularity(prompt.id);
    },
  },
  computed: {
    authenticated() {
      return this.$store.getters.getAuth;
    },

    user() {
      return this.$store.getters.getUser;
    },

    categories() {
      let categories = {};
      this.prompts.forEach((prompt) => {
        if (prompt.category) {
          if (categories[prompt.category.name]) {
            categories[prompt.category.name]++;
          } else {
            categories[prompt.category.name] = 1;
          }
        }
      });
      return Object.keys(categories).map((category) => {
        return {
          category_name: category,
          prompt_count: categories[category],
        };
      });
    },

    filteredPrompts() {
      if (!this.selectedCategory) {
        return this.prompts.filter((prompt) => prompt.public === true);
      }

      if (this.selectedCategory == "Saved") {
        return this.prompts.filter((prompt) =>
          this.savedPromptIds.includes(prompt.id)
        );
      }

      if (this.selectedCategory == "Custom") {
        return this.prompts
          .filter((prompt) => this.customPromptIds.includes(prompt.id))
          .sort((b, a) => new Date(a.created_at) - new Date(b.created_at));
      }

      if (this.selectedCategory == "Public") {
        return this.prompts.filter((prompt) => prompt.public === false);
      }

      // console.log(this.selectedCategory)
      // console.log(this.prompts)

      return this.prompts.filter(
        (prompt) =>
          prompt.category &&
          prompt.category.name === this.selectedCategory &&
          prompt.public === true
      );
    },

    systemPromptTitle() {
      return this.$store.state.systemPromptTitle;
    },
  },
};
</script>
<style scoped>
/* Add your custom styles here */

button {
  cursor: pointer;
}
</style>
