<template>
    <transition name="fade">
        <div class="fixed inset-0 z-50 flex items-center justify-center" @click.self="$emit('close')">

            <div class="bg-black opacity-75 absolute inset-0" @click="$emit('close')"></div>

            <div class="w-11/12 md:w-1/2 border-2 p-2 md:p-8 z-10 relative bg-white">

                <div class="text-left">

                    <h1 class="text-2xl text-black font-bold"> Sticky Prompt </h1>

                    <p> Sticky Prompts will be attached to all your messages, so you don't have to repeat yourself.</p>

                    <p> Try prompts like <span class="font-bold text-purple-800"> Translate this to German </span> or <span
                            class="font-bold text-purple-800"> Remember to always use TailwindCSS </span>. </p>

                    <div class="flex max-h-screen py-4">

                        <textarea class="bg-gold-100 p-4 rounded w-full md:w-2/3 h-36" v-model="userPrompt"></textarea>

                    </div>

                    <div class="flex justify-left">

                        <ButtonRoyal class="button-primary text-left ml-0 mr-2" text="Use Prompt" icon="magic_button"
                            @click="selectPrompt(true)"></ButtonRoyal>
                        <ButtonRoyal @click="$emit('close')" class="button-secondary ml-0 mr-2" text="Close" icon="close">
                        </ButtonRoyal>
                        <ButtonRoyal v-if="sticky !== ''" @click="selectPrompt(false)" class="button-red-tertiary ml-0"
                            text="Remove Current Prompt" icon="block">
                        </ButtonRoyal>


                    </div>


                </div>


            </div>
        </div>
    </transition>
</template>
  
  
<script>
import Accordion from '@/components/Accordion.vue'
import ButtonRoyal from '@/components/ButtonRoyal.vue'

export default {
    name: "ModalPrompts",
    components: {
        Accordion,
        ButtonRoyal,
    },
    data() {
        return {
            prompts: [],
            selectedCategory: false,
            showEdit: false,
            userPrompt: '',
            omitSticky: false,
        };
    },
    mounted() {
        fetch("https://gptroyal.com/stripe-php/select_prompts.php")
            .then((response) => response.json())
            .then((data) => {
                this.prompts = data;
            });

        if (this.sticky.Prompt) {
            // console.log(this.sticky.Prompt);
            this.userPrompt = this.sticky.Prompt
            console.log(this.userPrompt);
        }
    },
    methods: {

        upvote(prompt) {
            prompt.Upvotes++;
            // Send a POST request to update the upvotes in the database
            fetch("https://gptroyal.com/stripe-php/upvote_prompt.php", {
                method: "POST",
                body: JSON.stringify(prompt),
            });
        },
        selectCategory(category) {

            if (category == 0) {
                this.selectedCategory = false;
                return;
            }

            this.selectedCategory = category.category_name;

            console.log(this.selectedCategory)
            // console.log(category.name)

        },

        selectPrompt(addPrompt) {

            if (this.userPrompt !== '' && addPrompt) {

                let sticky = {}

                sticky.Prompt = this.userPrompt
                sticky.Omit = this.omitSticky
                sticky.Title = this.userPrompt

                this.$store.commit('updateTemplatePrompt', sticky);
                this.$emit('close');

            } else {

                let sticky = {}
                this.$store.commit('updateTemplatePrompt', sticky);
                this.$emit('close');
            }

        }

    },
    computed: {
        categories() {
            let categories = {}
            this.prompts.forEach(prompt => {
                if (categories[prompt.category_name]) {
                    categories[prompt.category_name]++
                } else {
                    categories[prompt.category_name] = 1
                }
            })
            return Object.keys(categories).map(category => {
                return {
                    category_name: category,
                    prompt_count: categories[category]
                }
            })
        },

        filteredPrompts() {
            if (!this.selectedCategory) {
                return this.prompts;
            }

            // console.log(this.selectedCategory)
            // console.log(this.prompts)

            return this.prompts.filter(prompt => prompt.category_name === this.selectedCategory);
        },

        sticky() {
            return this.$store.state.templatePrompt;
        }

    },
};
</script>
<style scoped>
/* Add your custom styles here */


button {
    cursor: pointer;
}
</style>
  