<template>
    <div class="w-1/3 sm:w-1/4 pr-4">
      <!-- <h2 class="font-bold text-left text-2xl mb-4">Prompt Library</h2> -->

      <ul class="list-none font-normal">
        <h2 class="font-bold text-lg sm:text-xl mb-2">My Library</h2>
  
        <div class="flex justify-left items-center">
          <h3
            class="cursor-pointer mb-2 mr-2"
            @click="selectCategory(-1)"
            :class="{ 'font-bold': selectedCategory == 'Saved' }"
          >
            Saved Prompts
          </h3>
  
          <p class="bg-gold-50 rounded-full px-2 py-1 text-black">
            {{ savedPromptIds.length }}
          </p>

        </div>

        <div class="flex justify-left items-center">
          <h3
            class="cursor-pointer mb-2 mr-2"
            @click="selectCategory(-2)"
            :class="{ 'font-bold': selectedCategory == 'Custom' }"
          >
            Custom Prompts
          </h3>
  
          <p class="bg-gold-50 rounded-full px-2 py-1 text-black">
            {{ customPromptIds.length }}
          </p>

        </div>
  
        <h2 class="font-bold text-lg sm:text-xl mt-4 mb-2">Public Prompts</h2>
  
        <h3
          class="cursor-pointer mb-2 text-sm sm:text-base"
          @click="selectCategory(0)"
          :class="{ 'font-bold': selectedCategory == '' }"
        >
          Popular Prompts
        </h3>
  
        <li
          class="cursor-pointer mb-2 font-normal flex justify-left items-center"
          @click="selectCategory(category)"
          v-for="category in categories"
          :key="category.category_name"
        >
          <h3
            :class="{
              'font-bold': category.category_name == selectedCategory,
            }"
            class="mr-2 text-sm sm:text-base"
          >
            {{ category.category_name }}
          </h3>
  
          <!-- <div class="bg-gold-50 rounded-full px-2 py-1 text-black">
            {{ category.prompt_count }}
          </div> -->
        </li>

        <h3
          class="cursor-pointer mb-2 text-sm sm:text-base"
          @click="selectCategory(-3)"
          :class="{ 'font-bold': selectedCategory == 'Public' }"
        >
          Public User Prompts
        </h3>


      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      categories: {
        type: Array,
        required: true,
      },
      selectedCategory: {
        type: String,
        required: true,
      },
      savedPromptIds: {
        type: Array,
        required: true,
      },
      customPromptIds: {
        type: Array,
        required: true,
      },
    },
    methods: {
      selectCategory(category) {
        this.$emit("selectCategory", category);
      },
    },
  };
  </script>
  