<template>
  <div class="bg-gold-50 h-screen w-full pt-4 overflow-y-auto">

    <!-- <p class="invisible px-4 py-2 cursor-pointer text-white w-3/4 mx-auto rounded-xl mb-4 bg-purple-500"
      @click="$emit('openPro')"> Go Pro</p> -->

    <div class=" text-left">

      <h1 class="text-black font-bold text-4xl my-4 pl-6"> Chats</h1>

      <!-- New Chat button -->
      <p :class="{ 'bg-gold-500': 0 === currentChatId, 'bg-gold-50 hover:bg-gold-100': 0 !== currentChatId }"
        class=" py-2 cursor-pointer pl-6 text-black mb-4" @click="newChat()">New Chat</p>
      <!-- Existing chats buttons -->

      <!-- Existing chats buttons -->
      <p :class="{ 'bg-gold-500': chat.id === currentChatId, 'bg-gold-50 hover:bg-gold-100': chat.id !== currentChatId }"
        v-for="chat in sortedChats" :key="chat.id" @click="changeChat(chat.id)"
        class="cursor-pointer py-2 pl-6 text-black mb-2">{{ chat.name }}</p>

    </div>

  </div>
</template>
  
<script>
export default {
  name: 'SideBar',
  computed: {

    sortedChats() {
      return this.$store.state.chats.sort((a, b) => b.id - a.id);
    },

    currentChatId() {
      return this.$store.state.currentChat;
    },

  },

  methods: {
    changeChat(id) {

      console.log('change chat to: ', id);

      this.$store.commit('changeChat', id);

    },

    newChat() {

      this.$store.commit('changeChat', 0);

      console.log(this.$store.getters.getChatById(this.$store.state.currentChat));


    }

  },

};
</script>
  
  