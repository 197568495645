<template>
    <div class="w-full">
      <div v-if="selectedCategory == 'Saved' && !authenticated" class="text-left">
        You must log in to save prompts. <br> However, you can browse public prompts by clicking on the categories on the left.
  
        <ButtonRoyal
          @click="$emit('openLogin')"
          class="button-blue-primary mb-4 mt-4 ml-0"
          text="Log In"
          icon="Login"
        >
        </ButtonRoyal>
      </div>
  
      <div
        v-else-if="
          selectedCategory == 'Saved' &&
          authenticated &&
          savedPromptIds.length == 0
        "
        class="text-left"
      >
        You haven't saved any prompts yet. <br> Browse & Save public prompts by clicking on the categories on the left.
      </div>
    </div>
  </template>
  
  <script>
  import ButtonRoyal from "@/components/ButtonRoyal.vue";
  
  export default {
    components: {
      ButtonRoyal,
    },
    props: {
      selectedCategory: {
        type: String,
        required: true,
      },
      authenticated: {
        type: Boolean,
        required: true,
      },
      savedPromptIds: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  