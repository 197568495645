<template>
    <transition name="fade">
        <div class="fixed inset-0 z-50 flex items-center justify-center" @click.self="$emit('close')">
            <div class="bg-black opacity-75 absolute inset-0" @click="$emit('close')"></div>
            <div class=" w-11/12 md:w-1/3 border-2 p-2 md:p-8 z-10 relative bg-white" @click.stop>

                <!-- <h1 class="text-6xl text-center mb-2">👑</h1> -->
                <!-- <div class="flex justify-center items-center mb-2">
                    <img src="@/assets/coffee.png" alt="Crown" width="70" class="mx-auto">
                </div> -->
                <div class="w-11/12 md:w-5/6 mx-auto my-4 text-justify font-family">
                    <h1 class="text-black text-1xl md:text-2xl text-left font-bold mb-2">Select Model</h1>
                    <p>Please select the language-model you'd like to use with GPT Royal.</p>

                    <div class="relative inline-flex mt-4">
                        <select v-model="selectedModel"
                            class="block appearance-none w-full bg-white border-2 border-gold-500 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option value="gpt-3.5-turbo">GPT-3.5-Turbo (faster, very inexpensive)</option>
                            <option value="gpt-4">GPT-4 (smarter, slower, more expensive)</option>
                        </select>
                    </div>

                    <p class="mt-4"> <span class="text-red-500 font-bold">Warning: </span> GPT-4 is in Limited Beta. If you select the model without being eligible for access, GPT Royal may throw errors. 
                    
                    <a class="underline font-bold text-purple-500" target="_blank" href="https://openai.com/waitlist/gpt-4-api"> Join OpenAI's GPT-4 waitlist</a>
                    
                    </p>

                    <p class="mt-2"> GPT Royal will remember your choice for all future sessions.</p>

                    <ButtonRoyal class="mt-4 button-primary ml-0" @click="$emit('close')" text="SAVE" icon="save"> 
                    </ButtonRoyal>

                </div>

                

            </div>

        </div>
    </transition>
</template>
  
<script>

import ButtonRoyal from './ButtonRoyal.vue';

export default {
    name: 'ModalSettings',
    components: {
        ButtonRoyal,

    },
    props: {

    },
    data() {
        return {

            selectedModel: localStorage.getItem('selectedModel') || 'gpt-3.5-turbo'


        }

    },
    methods: {



    },

    mounted() {


    },

    computed: {

    },

    watch: {

        selectedModel(newVal) {

            this.$store.commit('updateModel', newVal);

        }
    }

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.animate-pulse {
    animation: pulse 1s infinite;
}

.block-comp {
    margin-top: -0.5rem;
}
</style>
  