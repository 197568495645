<template>
  <div id="prompt-creator" class="w-full max-w-md my-8">
    <form
      @submit.prevent="createPrompt"
      class="bg-white rounded px-8 pt-6 pb-8 mb-4"
    >
      <div class="mb-4">
        <label
          for="prompt-title"
          class="block text-gray-700 text-sm font-bold mb-2"
          >Prompt Title:</label
        >

        <input
          type="text"
          id="prompt-title"
          v-model="promptTitle"
          required
          class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
        />
      </div>

      <div class="mb-4">
        <label
          for="prompt-text"
          class="block text-gray-700 text-sm font-bold mb-2"
          >Prompt:</label
        >
        <textarea
          id="prompt-text"
          v-model="promptText"
          rows="4"
          cols="50"
          required
          class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
        ></textarea>
      </div>

      <div class="mb-4">
        <label
          for="category-dropdown"
          class="block text-gray-700 text-sm font-bold mb-2"
          >Category:</label
        >
        <select
          id="category-dropdown"
          v-model="selectedCategory"
          class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
        >
          <option
            v-for="category in categories"
            :key="category.id"
            :value="category.id"
          >
            {{ category.name }}
          </option>
        </select>
      </div>

      <p>
        Custom Prompts are by default public and available for everyone in the
        'User Prompts' tab. After review, they may be added to the category you
        set here.
      </p>

      <div class="flex">
        <ButtonRoyal
          v-if="preFillID == null"
          type="submit"
          class="button-blue-primary mb-4 mt-4 ml-0"
          text="Add Prompt"
          icon="add"
        >
        </ButtonRoyal>

        <ButtonRoyal
          v-else
          type="submit"
          class="button-blue-primary mb-4 mt-4 ml-0"
          text="Update Prompt"
          icon="add"
        >
        </ButtonRoyal>

        <ButtonRoyal
          @click="$emit('close')"
          class="button-tertiary mb-4 mt-4 ml-0"
          text="Cancel"
          icon="cancel"
        >
        </ButtonRoyal>
      </div>
    </form>
  </div>
</template>

<script>
import ButtonRoyal from "@/components/ButtonRoyal.vue";
import supabase from "@/supabase";

export default {
  components: {
    ButtonRoyal,
  },
  props: {
    preFillPrompt: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      promptTitle: "",
      promptText: "",
      selectedCategory: "",
      categories: [], // Initialize as an empty array
      preFillID: null,
    };
  },

  async created() {
  // Fetch data from a Supabase table called "categories"
  const { data, error } = await supabase
    .from("categories")
    .select("id, name")
    .order("name", { ascending: true });

  this.categories = data;

  // console.log(this.categories);

  if (error) {
    console.error("Error fetching data:", error);
  } else {
    // console.log("Fetched data:", data);
  }

  // Update the variables based on preFillPrompt
  if (this.preFillPrompt) {
    this.promptTitle = this.preFillPrompt.title || "";
    this.promptText = this.preFillPrompt.prompt || "";
    this.preFillID = this.preFillPrompt.id || null;

    // Find the category ID based on the category name
    const category = this.categories.find(
      (category) => category.name === this.preFillPrompt.category.name
    );

    if (category) {
      this.selectedCategory = category.id;
    } else {
      this.selectedCategory = "";
    }
  }
},

  methods: {
    async createPrompt() {
      // create new prompt
      if (this.preFillID == null) {
        try {
          const { data, error } = await supabase.from("prompts").insert([
            {
              title: this.promptTitle,
              prompt: this.promptText,
              category_id: this.selectedCategory,
              user_id: this.user.id,
            },
          ]);

          if (error) {
            console.log("Error:", error);
          } else {
            // alert("Prompt created!");
            // You can clear the input fields after successful insertion if needed
            this.promptTitle = "";
            this.promptText = "";
            this.selectedCategory = "";
            this.$emit("fetchPrompts");
            this.$emit("close");
          }
        } catch (err) {
          console.error("Error:", err);
        }

        // update existing prompt
      } else {
        try {
          const { data, error } = await supabase
            .from("prompts")
            .update({
              title: this.promptTitle,
              prompt: this.promptText,
              category_id: this.selectedCategory,
            })
            .eq("id", this.preFillID);

          if (error) {
            console.log("Error:", error);
          } else {
            // alert("Prompt updated!");
            // You can clear the input fields after successful update if needed
            this.promptTitle = "";
            this.promptText = "";
            this.selectedCategory = "";
            this.$store.commit("editCustomPrompt", false);
            this.$emit("fetchPrompts");
            this.$emit("close");
          }
        } catch (err) {
          console.error("Error:", err);
        }
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    categoriesReady() {
      return this.categories.length > 0;
    },
  },

  // Add this to your Vue component
  watch: {

  },
};
</script>
