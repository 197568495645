<template>
  <div v-if="isMounted">
    <div ref="kofiWidget"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMounted: false
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://storage.ko-fi.com/cdn/scripts/overlay-widget.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      this.isMounted = true;
      kofiWidgetOverlay.draw("nickschnee", {
        type: "floating-chat",
        "floating-chat.donateButton.text": "Support me",
        "floating-chat.donateButton.background-color": "#794bc4",
        "floating-chat.donateButton.text-color": "#fff"
      }, this.$refs.kofiWidget);
    };
  }
};
</script>
