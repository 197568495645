<template>
  <div class="mx-auto flex">

    <ButtonRoyal :banner="'NEW'" @click="$emit('openCharacter')" class="button-blue-primary mb-4 mr-4" :text="systemPromptTitle"
      icon="person" :running="false"> </ButtonRoyal>

    <ButtonRoyal v-if="!this.sticky.Prompt" @click="$emit('openPrompts')" class="button-blue mb-4 mr-2"
      text="Sticky-Prompt: OFF" icon="magic_exchange"> </ButtonRoyal>

    <ButtonRoyal v-else @click="$emit('openPrompts')" class="button-blue-primary mb-4 mr-2" :text="sticky.Title"
      icon="magic_exchange" :running="true"> </ButtonRoyal>

  </div>
</template>
  
<script>
import ButtonRoyal from './ButtonRoyal.vue';

export default {
  name: 'ButtonRow',
  components: {
    ButtonRoyal,
  },
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {

  },

  computed: {

    sticky() {

      return this.$store.state.templatePrompt;

    },

    systemPromptTitle() {

    return this.$store.state.systemPromptTitle;

  },

  }
};
</script>
  