<template>
  <div class="w-full font-sans">
    <p @click="isOpen = !isOpen"
      class="cursor-pointer flex text-left justify-between items-center w-full p-2 font-bold"
      :class="[titleBgColor, isOpen ? titleBgColorOpen : '']">
      <span class="text-left">{{title}}</span>
      <span :class="{ 'rotate-180': isOpen }" class="transition-transform transform">
        <svg class="h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 13.333l6.667-6.666-1.334-1.334-5.333 5.333-5.333-5.333-1.334 1.334z">
          </path>
        </svg>
      </span>
    </p>
    <div v-show="isOpen" class="text-left p-4" :class="contentBgColor">
      <p v-html="content" class="text-gray-800 max-w-full content-body">
        
      </p>

    </div>
  </div>
</template>
  
<script>
export default {
  name: 'Accordion',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    titleBgColor: {
      type: String,
      default: 'bg-gray-100',
    },
    titleBgColorOpen: {
      type: String,
      default: 'bg-gray-200',
    },
    contentBgColor: {
      type: String,
      default: 'bg-gray-50',
    },
  },
  data() {
    return {
      isOpen: false,

    };
  },
  methods: {

  },
  mounted() {

  },
};
</script>