<template>
  <transition name="fade">
    <div
      class="fixed inset-0 z-50 flex items-center justify-center"
      @click.self="$emit('close')"
    >
      <div
        class="bg-black opacity-25 absolute inset-0"
        @click.self="$emit('close')"
      ></div>
      <div class="w-11/12 md:w-1/3 p-8 z-10 relative bg-white" @click.stop>
        <form @submit.prevent="sendMagicLink">
          <div class="mb-4 leading-relaxed font-sans">
            <h1 class="text-2xl text-left text-black font-bold">
              Enter your Email
            </h1>
            <p class="block text-black text-left my-2">
              Some features are only available to registered users. Enter your
              email to receive a magic link for login.
            </p>

            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Enter your email"
              v-model="email"
              required
            />
          </div>
          <div class="ml-0 flex">

            <ButtonRoyal
                type="submit"
                class="button-blue-primary mx-0 mr-4 mb-4 mt-4 ml-0"
                text="Send Magic Link"
                icon="Magic_Button"
              >
              </ButtonRoyal>

              <ButtonRoyal
              @click="$emit('close')"
                class="button-tertiary mx-0 mb-4 mt-4 ml-0"
                text="Close"
                icon="close"
              >
              </ButtonRoyal>

          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import supabase from "@/supabase";
import ButtonRoyal from "@/components/ButtonRoyal.vue";

export default {
  components: {
    ButtonRoyal,
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async sendMagicLink() {
      try {
        const { error } = await supabase.auth.signInWithOtp({
          email: this.email,
          emailRedirectTo: "http://localhost:8080",
        });
        if (error) {
          throw error;
        }
        alert("Magic link sent! Check your email.");
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>
