<template>
  <div class="p-8">

    <!-- <div class="flex justify-center">
  <ul class="text-center text-purple-500 list-none leading-loose">
    <li class="text-purple-700">Attach (hidden) prompts to every message </li>
    <li class="text-purple-700"> Code highlighting </li>
    <li class="text-purple-700">No login, no downtime</li>
  </ul>
</div> -->


    <!-- <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div class="bg-purple-50  shadow-lg p-4 text-black">
        <h3 class="text-xl font-bold mb-2">At Your Service</h3> -->

    <!-- <ul class="w-1/2 mx-auto">

          <li> No Login</li>
          <li> No Downtime</li>
          <li> Blazing Fast</li>

        </ul> -->

    <!-- <p class="text-black mb-4">While ChatGPT may randomly log you out or even be unavailable at times, GPT Royal will
          always be at your service. <br> <br> <span class="text-purple-800 font-bold"> And it's blazing fast. </span>
        </p>

      </div>

      <div class="bg-purple-50  shadow-lg p-4 text-black">
        <h3 class="text-xl font-bold mb-2">Your Wish is my Command</h3>
        <p class="text-black mb-4">Automatically attach custom prompts like <span class="text-purple-800 font-bold"> translate this to german </span> or <span class="text-purple-800 font-bold"> use Tailwind CSS </span>
          to every message. <br> <br> <span class="text-purple-800 font-bold"> Do I have to repeat myself? No.</span>
      </p>
      </div>

      <div class="bg-purple-50 rounded-lg shadow-lg p-4 text-black">
        <h3 class="text-xl font-bold mb-2">GPT-4 Ready</h3>
        <p class="text-black mb-4"> If you have a working API Key for GPT-4, choose this model from settings.  </p>
      </div> -->

    <!-- <div class="bg-purple-50 rounded-lg shadow-lg p-4 text-black">
        <h3 class="text-xl font-bold mb-2">Your Servant, or Buddy, or Both</h3>
        <p class="text-black mb-4"> Choose from different AI characters depending on the task you're working on. </p>
      </div> -->
    <!-- <div class="bg-purple-50  shadow-lg p-4 text-black">
        <h3 class="text-xl font-bold mb-2">Your Browser is my Castle</h3>
        <p class="text-black mb-4"> GPT Royal lives <span class="text-purple-800 font-bold">  locally in your browser </span>. Your chat history and other data is never shared with GPT Royal. </p>
      </div> -->
    <!-- <div class="bg-purple-50 rounded-lg shadow-lg p-4 text-black">
        <h3 class="text-xl font-bold mb-2">A long (chat) History</h3>
        <p class="text-black mb-4">Keep track of your chat history and easily reference previous conversations to stay on top of your work.</p>
      </div> -->
    <!-- <div class="bg-purple-400 rounded-lg shadow-lg p-4 text-black">
        <h3 class="text-xl font-bold mb-2">Join the Royal Family</h3>
        <p class="text-black mb-4"></p>
      </div> -->



    <!-- </div> -->

  </div>
</template>
  
<script>

import Accordion from '@/components/Accordion.vue'


export default {
  name: 'Info',
  components: {
    Accordion
  },
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {

  },
};
</script>
