<template>
    <div class="w-2/3 sm:w-3/4">
      <h2 v-if="!selectedCategory" class="font-bold mb-4 text-left text-xl sm:text-lg">
        Popular Prompts
      </h2>
  
      <h2
        v-else-if="selectedCategory == 'Saved'"
        class="font-bold mb-4 text-left text-xl sm:text-lg"
      >
        Saved Prompts
      </h2>
  
      <h2 v-else class="font-bold mb-4 text-left text-xl sm:text-lg">
        {{ selectedCategory }}
      </h2>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      selectedCategory: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  