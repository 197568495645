<template>
  <div class="">

    <div class="h-screen flex flex-col justify-end py-2 px-4 bg-white">

      <div class="flex-grow flex flex-col overflow-y-scroll " ref="scrollContainer">


        <!-- API KEY  -->
        <!-- API KEY  -->
        <!-- API KEY  -->
        <!-- API KEY  -->

        <div>

          <h1
            class="text-4xl mt-10 font-bold text-transparent bg-gradient-to-r from-gold-500 to-purple-500 bg-white bg-clip-text inline-block">
            GPT ROYAL</h1>

          <p class="mt-4 text-xl"> A helpful AI assistant who makes you feel like royalty.</p>


        </div>

        <!-- ENDE API KEY  -->
        <!-- ENDE API KEY  -->
        <!-- ENDE API KEY  -->
        <!-- ENDE API KEY  -->

        <Info></Info>

        <div class="mb-8">
          <ButtonRoyal v-if="!apiKeyValid" @click="$emit('open')" class="button-primary"
            text="Enter your API Key to start chatting" icon="key"> </ButtonRoyal>

          <ButtonRoyal v-else @click="$emit('open')" class="button-secondary" text="You're all set, start chatting!"
            icon="check_circle"> </ButtonRoyal>

        </div>


        <div v-if="currentChatId !== 0" v-for="message in currentChat.chatHistory" :key="message.id"
          :class="message.role !== 'assistant' ? 'flex flex-row-reverse text-white mb-4' : 'flex text-white mb-4'">

          <span class="chat-bubble" v-if="message.role !== 'user'"
            :class="message.role !== 'assistant' ? 'text-black bg-gold-50 px-4 py-2 inline-block max-w-2/3' : 'bg-purple-50  text-black px-4 py-2 inline-block max-w-2/3'"
            v-html="displayContent(message)">
          </span>

          <span class="chat-bubble" v-else
            :class="message.role !== 'assistant' ? 'text-black bg-gold-50 px-4 py-2 inline-block max-w-2/3' : 'bg-purple-50  text-black px-4 py-2 inline-block max-w-2/3'">
            {{ displayContent(message) }}
          </span>

        </div>


        <div v-if="isThinking"
          class="animate-pulse w-1/2 text-black text-left mb-4  bg-purple-50 px-4 py-2 inline-block max-w-2/3">
          <span> Assistant is thinking...</span>
        </div>

        <div v-if="chatError" class="w-1/2 text-black text-left mb-4  bg-red-100 px-4 py-2 inline-block max-w-2/3">
          <span v-html="this.errorMsg"> </span>
        </div>

      </div>

      <ButtonRow @openCharacter="$emit('openCharacter')" @openPrompts="$emit('openPrompts')"> </Buttonrow>

      <div class="block md:hidden">
        <div class="flex justify-between w-full">
          <ButtonRoyal text="Chats" icon="chat_bubble" class="button-secondary" @click="$emit('toggleChats', $event)">

          </ButtonRoyal>
          <ButtonRoyal text="Controls" icon="settings" class="button-secondary" @click="$emit('toggleControls', $event)">

          </ButtonRoyal>
        </div>
      </div>



      <div class="flex justify-between items-center mt-4 font-sans border-gold-500 border-t-2">

        <textarea @keyup.enter="processInput" v-model="userInput" placeholder="ask me anything ..."
          class="resize-y w-full h-12 md:h-36 p-6 bg-white text-black focus:outline-none"></textarea>

        <!-- <ButtonRoyal @click="processInput" class="button-secondary" text="Prompt" icon="attach_file_add"> </ButtonRoyal> -->

        <div class="p-4">
          <ButtonRoyal @click="processInput" class=" button-primary" text="Send" icon="send"> </ButtonRoyal>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

window.copyToClipboard = function (event) {
  const pre = event.target.parentNode.querySelector('pre');
  const text = pre.textContent;
  navigator.clipboard.writeText(text).then(() => {
    alert('Code copied to clipboard!');
  });
}

import 'highlight.js/styles/monokai-sublime.css';

import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
import php from 'highlight.js/lib/languages/php';
import csharp from 'highlight.js/lib/languages/csharp';
import java from 'highlight.js/lib/languages/java';
import css from 'highlight.js/lib/languages/css';

hljs.registerLanguage('xml', xml);
hljs.registerLanguage('php', php);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('java', java);
hljs.registerLanguage('css', css);

import { marked } from 'marked';
import ButtonRow from '@/components/ButtonRow.vue'
import Info from '@/components/Info.vue'
import ButtonRoyal from '@/components/ButtonRoyal.vue'

export default {
  name: 'Chat',
  components: {
    ButtonRow,
    Info,
    ButtonRoyal
  },
  data() {
    return {
      userInput: '',
      isThinking: false,
      isError: false,
      errorMsg: '',

    };
  },
  updated() {
    this.$nextTick(() => {
      // Your function to execute after the loop has finished rendering
      // console.log('formatting')
      this.highlightCode();
    });
  },
  methods: {

    highlightCode() {

      hljs.highlightAll(); // Enable auto-detection

    },

    copyToClipboard(event) {
      const pre = event.target.parentNode.querySelector('pre');
      const text = pre.textContent;
      navigator.clipboard.writeText(text).then(() => {
        alert('Code copied to clipboard!');
      });
    },

    convertMD(text) {
      return marked(text);
    },

    lookforKey() {

      if (localStorage.getItem('apiKey')) {
        let key = localStorage.getItem('apiKey');


        this.$store.commit('saveKey', key);

      }

    },

    processInput(event) {

      // validate input
      // validate input
      // validate input
      // validate input
      if (!this.userInput || event.keyCode === 13 && event.shiftKey) {

        return;

      } else if (!this.apiKeyValid) {

        this.$emit('open');
        return;

      }

      // when a sticky prompt is active, then append the sticky prompt to the user input
      // when a sticky prompt is active, then append the sticky prompt to the user input
      // when a sticky prompt is active, then append the sticky prompt to the user input
      // when a sticky prompt is active, then append the sticky prompt to the user input

      if (this.sticky.Prompt) {

        console.log(this.sticky.Prompt)

        var prompt = this.addStickyPrompt(this.userInput);

      } else {

        var prompt = this.userInput;

      }

      // set the status to thinking, remove userinput
      // set the status to thinking, remove userinput
      // set the status to thinking, remove userinput
      // set the status to thinking, remove userinput

      this.isThinking = true;
      this.$store.commit('showChatError', false);
      this.userInput = '';

      // if this is a new chat, then make new chat
      // if this is a new chat, then make new chat
      // if this is a new chat, then make new chat
      // if this is a new chat, then make new chat

      if (this.currentChatId === 0) {

        console.log("making new chat")

        // make new chat in store
        this.$store.commit('newChat', {
          id: 2,
          role: 'user',
          content: prompt,
        });


        // else, update the chat
        // else, update the chat
        // else, update the chat
        // else, update the chat

      } else {


        this.$store.commit('updateChat', {
          id: this.currentChat.chatHistory.length + 1,
          role: 'user',
          content: prompt,
        });

      }

      // scroll to the bottom of the chat
      // scroll to the bottom of the chat
      // scroll to the bottom of the chat
      // scroll to the bottom of the chat
      // scroll to the bottom of the chat
      // scroll to the bottom of the chat

      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        container.scrollTo(0, container.scrollHeight);
      });

      // in any case, fetch the result from the API
      // in any case, fetch the result from the API
      // in any case, fetch the result from the API
      // in any case, fetch the result from the API

      fetch('https://api.openai.com/v1/chat/completions', {
        body: JSON.stringify({
          model: this.model,
          messages: this.currentChat.chatHistory.map(({ role, content }) => ({ role, content })),
          temperature: 0.3,
          max_tokens: 2000
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.apiKey
        }
      })
        .then((response) => {
          console.log(response); //If you want to check the full response
          if (response.ok) {
            response.json().then((json) => {

              // update the chat with the new response
              // update the chat with the new response
              // update the chat with the new response
              // update the chat with the new response

              this.$store.commit('updateChat', {
                id: this.currentChat.chatHistory.length + 1,
                role: 'assistant',
                content: this.convertMD(json.choices[0].message.content),
              });


              // scroll again to the bottom, when new message was added
              // scroll again to the bottom, when new message was added
              // scroll again to the bottom, when new message was added
              // scroll again to the bottom, when new message was added

              this.$nextTick(() => {
                const container = this.$refs.scrollContainer;
                container.scrollTo(0, container.scrollHeight);
              });

              // set thinking to false again
              // set thinking to false again
              // set thinking to false again
              // set thinking to false again

              this.isThinking = false;

            });
          } else {

            this.isThinking = false;

            this.$store.commit('showChatError', true);

            if (response.statusText == '') {

              this.errorMsg = "<b> There was an error with your request.</b> <br> We don't know what went wrong. Your conversation history may be too long. Try making a new chat.";

            } else {

              this.errorMsg = "Uh! This request returned an error. OpenAI returned the following message:" + response.statusText;

            }

            // scroll again to the bottom, when new message was added
            // scroll again to the bottom, when new message was added
            // scroll again to the bottom, when new message was added
            // scroll again to the bottom, when new message was added

            this.$nextTick(() => {
              const container = this.$refs.scrollContainer;
              container.scrollTo(0, container.scrollHeight);
            });


          }
        })
        .catch((error) => {
          console.error(error);
          console.log('there was an error!!')

          // set thinking to false again
          this.isThinking = false;
          this.isError = true;


        });

    },

    addStickyPrompt(userInput) {

      const stickyPrompt = this.sticky.Prompt;

      // return userInput + ' ' + stickyPrompt;

      if (stickyPrompt.includes("[[prompt]]")) {

        return stickyPrompt.replace("[[prompt]]", userInput);

      } else {

        return userInput + ' ' + stickyPrompt;

      }

    }

  },

  mounted() {

    this.lookforKey()
    this.highlightCode()

    // Get a reference to the scroll container
    const container = this.$refs.scrollContainer;

    // Scroll to the bottom of the container
    container.scrollTo(0, container.scrollHeight);

  },

  computed: {
    displayContent() {
      return (message) => {
        if (message.role === 'user') {


          // if (this.sticky.Omit) {

          //   const updatedContent = message.content.replace(this.sticky.Prompt, '[STICKY]');
          //   message.content = updatedContent;

          //   return message.content;

          // }

          // console.log(message.content);

          return message.content;


        } else {
          // Display html as html and add <button> COPY CODE </button> in front of each <pre> element
          const regex = /<pre([\s\S]*?)>((.|\n)*?)<\/pre>/g;
          const replacement = '<div class="relative"><pre$1>$2</pre><button class="absolute top-0 right-0 bg-gold-500 text-white px-4 py-2 text-left" onclick="copyToClipboard(event)">COPY CODE</button></div>';
          return message.content.replace(regex, replacement);
        }
      };
    },


    currentChat() {

      return this.$store.getters.getChatById(this.$store.state.currentChat)

    },

    chatError() {

      return this.$store.state.chatError;

    },

    currentChatId() {
      return this.$store.state.currentChat;
    },

    apiKeyValid() {

      return this.$store.state.apiKeyValid;

    },

    apiKey() {

      return this.$store.state.apiKey;

    },

    systemPrompt() {

      return this.$store.state.systemPrompt;

    },

    sticky() {

      return this.$store.state.templatePrompt;

    },

    model() {

      return this.$store.state.selectedModel;

    },


  }


};
</script>

<style scoped>
.h-calc-screen {
  height: calc(100vh - 2rem);
  box-sizing: border-box;
}



.chat-bubble {

  /* font-family: Arial, Helvetica, sans-serif; */
  max-width: 100%;
}

.chat-bubble>>>p {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.chat-bubble>>>pre {
  color: black;
  text-align: left;
  background-color: #13071F;
  /* border-radius: 0.375rem; */
  padding: 1rem;
  margin: 1rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 100%;
  overflow-x: auto;
  word-wrap: break-word;
}

.chat-bubble>>>pre code {

  color: white;
  text-align: left;
  max-width: 100%;
  overflow-x: auto;
  word-wrap: break-word;
  background-color: #13071F;
  font-family: 'Courier New', Courier, monospace;
}

.chat-bubble>>>code {

  color: #311E47;
  font-weight: bold;

}

.chat-bubble>>>ul {

  text-align: left;
  list-style-type: circle;
  list-style-position: inside;
}

.chat-bubble>>>li {

  text-align: left;

}

.chat-bubble>>>ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
  line-height: 200%;
}

.thinking {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    background-color: #f2f2f2;
  }

  50% {
    background-color: #e6e6e6;
  }

  100% {
    background-color: #f2f2f2;
  }
}
</style>

