<template>
  <button class="mx-auto flex items-center text-center relative text-sm sm:text-base">
      <div v-if="banner != ''" class="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold py-1 px-2 rounded-bl-md">
          {{banner}}
      </div>

      <span :class="{ 'animate-spin': running }" class="text-sm sm:text-lg mr-2 material-symbols-outlined">{{ icon }}</span>
      {{ text }}
  </button>
</template>
  
<script>
export default {
    props: {
        icon: { type: String, default: 'check_circle' },
        text: { type: String, default: 'bibabutton' },
        running: { type: Boolean, default: false },
        banner: { type: String, default: '' }
    }
}
</script>
  
<style scoped>
/* Add your styles here */
.animate-spin {
    animation: spin 5s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }

}

@keyframes pulse {
  0% {
    background-color: #f2f2f2;
  }

  50% {
    background-color: #e6e6e6;
  }

  100% {
    background-color: #f2f2f2;
  }
}

</style>
  
  