<template>
    <transition name="fade">
        <div class="fixed inset-0 z-50 flex items-center justify-center" @click.self="$emit('close')">
            <div class="bg-black opacity-75 absolute inset-0" @click="$emit('close')"></div>
            <div class=" w-11/12 md:w-1/3 border-2 p-2 md:p-8 z-10 relative bg-white" @click.stop>

                <!-- <h1 class="text-6xl text-center mb-2">👑</h1> -->
                <!-- <div class="flex justify-center items-center mb-2">
                    <img src="@/assets/coffee.png" alt="Crown" width="70" class="mx-auto">
                </div> -->

                <div class="w-11/12 md:w-5/6 mx-auto my-4 text-justify font-family">

                    <h1 class="text-black text-1xl md:text-2xl text-left font-bold mb-2">Relax, there is no Pro Plan</h1>

                    <!-- <p class="w-5/6 md:w-3/4 mx-auto my-4 text-justify ">
                    Hands down, all current features are available for free in the Free Plan. However, by subscribing to
                    this Pro Plan, you show me that this tool is needed and you keep me motivated to be working on it.
                </p> -->


                    <p class="mb-2"> If you're a fan of GPT Royal, feel free to show your support by buying me a coffee.
                    </p>

                    <p> Also if you have any questions or feature requests, don't be shy! Hit me up on Twitter <a
                            target="_blank" class="text-purple-700 font-bold" href="https://twitter.com/nickschnee">
                            @nickschnee </a>. </p>


                        <ButtonRoyal class="button-primary mt-4 ml-0" text="Buy me a Coffee" icon="coffee" @click="kofi()"> </ButtonRoyal>


                </div>

                <!-- <KofiWidget> </KofiWidget> -->

                <!-- <div class="text-4xl font-bold text-center text-black mb-4">
                    US$4.90
                    <span class="block-comp text-sm text-sm">per month</span>
                </div> -->

                <!-- <div class="text-center">
                    <button class=" w-3/4 bg-gold-500 hover:bg-gold-600 text-white py-4 text-2xl font-bold p-10 hover:bg-gold-500"
                        @click="checkout()">SUBSCRIBE</button>
                </div> -->

                <!-- <div class="w-3/4 mx-auto mt-4">
                    <p class="text-left mb-2">This Pro Plan includes:</p>
                    <ul class="list-disc text-left list-inside mb-4">
                        <li class="text-purple-800">Supporting Nick, the indie maker of GPT Royal</li>
                        <li class="text-purple-800">Access to all upcoming Pro Features</li>
                        <li class="text-purple-800">Early-Bird price of $4.90 per month, forever</li>
                    </ul>
                </div> -->

            </div>


        </div>
    </transition>
</template>
  
<script>

import KofiWidget from "@/components/KofiWidget.vue";
import ButtonRoyal from "@/components/ButtonRoyal.vue";

export default {
    name: 'ModalKey',
    components: {
        KofiWidget,
        ButtonRoyal

    },
    props: {

    },
    data() {
        return {
            apiKey: '',
            checkingKey: false,
            message: '',
            isOpen: false

        }

    },
    methods: {

        kofi() {

            window.open('https://ko-fi.com/nickschnee', '_blank');


        },

        checkout() {


            fetch('https://gptroyal.com/stripe-php/checkout.php', {
                method: 'POST',
            })
                .then(response => response.json())
                .then(data => {

                    console.log(data)
                    window.location.href = data;
                })
                .catch(error => {
                    console.error('Error:', error);
                });


        }

    },

    mounted() {


    },

    computed: {

    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.animate-pulse {
    animation: pulse 1s infinite;
}

.block-comp {
    margin-top: -0.5rem;
}
</style>
  