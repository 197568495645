<template>
    <transition name="fade">
        <div class="fixed inset-0 z-50 flex items-center justify-center" @click.self="$emit('close')">
            <div class="bg-black opacity-25 absolute inset-0" @click.self="$emit('close')"></div>
            <div class=" w-11/12 md:w-1/3  p-8 z-10 relative bg-white" @click.stop>

                <!-- <h1 class="text-gray-600 text-4xl"> <span class="text-8xl"> 👑 </span> <br><br> GPT Royal</h1>
                <h2 class="text-2xl mt-8 mb-4 text-old-gold-500"></h2> -->
                <form @submit.prevent="handleSubmit">
                    <div class="mb-4 leading-relaxed font-sans">
                        <h1 class="text-2xl text-left text-black font-bold"> Please enter your API Key from OpenAI</h1>
                        <p class="block text-black text-left my-2"> GPT Royal uses the services (a so called API) of OpenAI to make the chat work. Because we don't sell API keys, you need to enter your personal API Key from the OpenAI Dashboard.   
                        </p>
                        <p class="block text-black text-left my-2"> <a class="text-purple-600 hover:text-purple-800 underline font-bold"
                                target="_blank" href="https://platform.openai.com/account/api-keys"> Click to make an Account with OpenAI and get your API Key
                        </a> </p>

                        <label class="" for="text">

                        </label>
                        <input v-if="!apiKeyValid" placeholder="SK-XXXXXXXXXXXXXXXXXXXXXX" v-model="apiKey" type="text"
                            name="text" id="text"
                            class="border-gold-500 mb-4 bg-white border-b-2 outline-none placeholder:text-gray-400 appearance-none w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline">

                        <div class="flex items-center">
                            <p class="mr-2 text-black text-xl">{{ storedApiKey }}</p>
                            <button v-if="apiKeyValid" @click="deleteKey()"
                                class="button-tertiary">DELETE KEY</button>
                        </div>

                        <p v-html="message" class="text-red-500 mb-4 font-bold text-left mb-2"> </p>

                        <!-- <Accordion class="" title="Why do I need this?" content="
                                                    <p> GPT Royal is a better interface for ChatGPT from OpenAI. It therefore relies on the services of OpenAI. OpenAI wants you to have an API Key to use their services. We don't make the rules.</p> 
                                                    <p> <a target='_blank' href='https://beta.openai.com/account/api-keys'> Get your API Key from OpenAI </a> </p>
                                                    "> </Accordion> -->

                        <Accordion class="mt-2" title="Is this free?"
                            content="<p>While GPT Royal has a free tier, OpenAI will charge you based on your usage of their API. </p> <p> Don't worry, this is very inexpensive. Heavy users who send several hundred messages a day will spend around $0.20 per day. Casual users will spend much less. </p>">
                        </Accordion>

                        <Accordion class="mt-2" title="Is my API Key safe?"
                            content="<p>GPT Royal stores your API Key locally in your browser storage. It never leaves your device. However, if somebody has access to your device, they could potentially retreive the key from your browser storage.</p>">
                        </Accordion>

                    </div>
                    <div class="flex justify-start">

                        <button v-if="!checkingKey && storedApiKey == '' " type="submit"
                            class="button-primary mr-4">CHECK
                            API KEY</button>

                        <button v-if="checkingKey" type="submit"
                            class="button-primary mr-4">
                            Checking API Key ... </button>

                        <button @click="$emit('close')"
                            class="button-secondary">
                            CLOSE</button>

                    </div>
                </form>
            </div>
        </div>
    </transition>
</template>
  
<script>

import Accordion from '@/components/Accordion.vue'

export default {
    name: 'ModalKey',
    components: {
        Accordion

    },
    props: {

    },
    data() {
        return {
            apiKey: '',
            checkingKey: false,
            message: '',

        }

    },
    methods: {

        deleteKey() {

            this.$store.commit('deleteKey');

        },

        lookforKey() {
            // if (localStorage.getItem('apiKey')) {
            //     // this.apiKey = localStorage.getItem('apiKey');
            //     this.$emit('close');
            // }
        },

        handleSubmit() {

            if (this.apiKey === '') {

                return;

            }

            this.checkingKey = true;


            fetch('https://api.openai.com/v1/chat/completions', {
                body: JSON.stringify({
                    model: 'gpt-3.5-turbo',
                    messages: [
                        { "role": "system", "content": "You are a helpful assistant." },
                        { "role": "user", "content": "Hello mate" },
                    ],
                    temperature: 0.3,
                    max_tokens: 2000
                }),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.apiKey
                }
            })
                .then((response) => {
                    if (response.ok) {
                        // API returned 200 status code, set key in local storage
                        // console.log('key working');

                        this.checkingKey = false;

                        this.$store.commit('saveKey', this.apiKey);

                        localStorage.setItem('apiKey', this.apiKey);

                        this.$emit('close');

                    } else {

                        this.checkingKey = false;

                        this.message = "API Key is not working! Please check that you entered your billing info at OpenAI. <br> <br> Don't worry, this is very inexpensive. Heavy users who send several hundred messages a day will spend around $0.20 per day. Casual users will spend much less. "

                        // console.error('API error:', response.status);

                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

    },

    mounted() {

        this.lookforKey()

    },

    computed: {

        apiKeyValid() {

            return this.$store.state.apiKeyValid;

        },

        storedApiKey() {

            const apiKey = this.$store.state.apiKey;

            if (apiKey === '') {
                return '';
            }
            const firstFour = apiKey.substring(0, 6);
            const lastTwo = apiKey.substring(apiKey.length - 2);
            return `${firstFour}...${lastTwo}`;

        },

    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.animate-pulse {
    animation: pulse 1s infinite;
}
</style>
  